export default [
    {
      _name: 'CSidebarNavItem',
      name: 'Home',
      to: '/dashboard',
      icon: 'cil-home',
    }
    // ,{
    //   _name: 'CSidebarNavDropdown',
    //   name: 'Add',
    //   route: '/add',
    //   icon: 'cil-smile-plus',
    //   items: [{
    //       name: 'Insight',
    //       to: '/insight/InsightModal'
    //     },
    //     {
    //       name: 'Idea',
    //       to: '/idea/IdeaModal'
    //     },
    //     {
    //       name: 'Concept',
    //       to: '/concept/ConceptModal'
    //     }
    //   ]
    // }
    ,{
      _name: 'CSidebarNavDropdown',
      name: 'Ideation Tank',
      route: '/tank',
      icon: 'cil-inbox',
      roles: ["User","Admin"],
      items: [{
          name: 'Insight Tank',
          to: '/insight/InsightTank'
        },
        {
          name: 'Idea Tank',
          to: '/idea/IdeaTank'
        },
        {
          name: 'Concept Tank',
          to: '/concept/ConceptTank'
        }
      ]
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Report',
      target: "_blank",
      //to: '/report/ConceptReport',
      href: 'https://app.powerbi.com/reportEmbed?reportId=f9942484-93d9-4323-99a7-729fd74e39e7&appId=e4fafd21-3584-40c5-8059-cd573379fa06&autoAuth=true&ctid=5db8bf0e-8592-4ed0-82b2-a6d4d77933d4&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXNvdXRoLWVhc3QtYXNpYS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
      icon: 'cil-file',
      roles: ["User","Admin"],
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'Ideation Manager',
      to: '/Config',
      icon: 'cil-user',
      roles: ["Admin"],
      //addLinkClasses: userprofile.Role === 'Admin' ? 'ShowMenu' : 'HideMenu',
      items: [
        {
          name: 'Manager setting',
          to: '/IdeationManager'
        },
        {
          name: 'Master Bu',
          to: '/config/MasterBu'
        },
        {
          name: 'Master Industry',
          to: '/config/MasterIndustry'
        },
        {
          name: 'Master Source',
          to: '/config/MasterSourceOfInsight'
        },
        {
          name: 'Master Segment',
          to: '/config/MasterSegment'
        },
        {
          name: 'Master SubSegment',
          to: '/config/MasterSubSegment'
        },
        {
          name: 'Master Criteria',
          to: '/config/MasterCriteria'
        },
      ]
    }
    // {
    //   _name: 'CSidebarNavItem',
    //   name: 'FI',
    //   to: '/FI',
    //   icon: 'cil-external-link',
    // }
 ];