<template>
  <CCardBody>
    <CDataTable
      :hover="hover"
      :striped="striped"
      :bordered="bordered"
      :small="small"
      :fixed="fixed"
      :fields="fields"
      :items-per-page="small ? 10 : 5"
      :items="dataTables"
      :dark="dark"
      :header="header"
      :loading="loading"
      pagination
    >
      <template #CreatorName="data">
        <td>
          <CRow class="col-12">
            
            <CCol sm="3"><b>Creator : </b>{{ data.item.CreatorName }}</CCol>
            <CCol sm="4"><b>Industry : </b>{{ data.item.Industry }}</CCol>
            <CCol sm="3"
              ><b>Created Date : </b>{{ data.item.CreatedDate }}</CCol
            >
            <CCol sm="2">

              <CButton v-if="data.item.ILike"
              size="sm" 
              color="success-custom" 
              variant="ghost" 
              class="mb-1 active" 
              @click="onClickLike(data.item)">
                <CIcon name="cil-thumb-up"></CIcon>
                {{ data.item.TotalLike }}
              </CButton>
              <CButton v-else
              size="sm" 
              color="success-custom" 
              variant="ghost" 
              class="mb-1" 
              @click="onClickLike(data.item)">
                <CIcon name="cil-thumb-up"></CIcon>
                {{ data.item.TotalLike }}
              </CButton>

              <CButton
              
                size="sm"
                variant="ghost"
                color="primary"
                v-on:click="onClickDetail('Insight',data.item)"
                class="mb-1"
              >
                <CIcon name="cil-magnifying-glass"></CIcon>
              </CButton>
            </CCol>
          </CRow>
          <CRow class="col-12">
            <CCol sm="10"
              ><b>Insight Detail : </b>{{ data.item.Description }}</CCol
            >
            <CCol sm="2">
              <CButton size="sm" color="info" v-on:click="onClickDetail('Idea',data.item)"
                >Add Idea
              </CButton>
            </CCol>
          </CRow>
        </td>
      </template>
    </CDataTable>
    <InsightModal
      :showModal.sync="InsightModal"
      @InsightModal-updated="InsightModal = $event"
      :item.sync="InsightObj"
      @InsightObject-updated="InsightObj = $event"      
    />
    <IdeaModal
      :showModal.sync="IdeaModal"
      @IdeaModal-updated="IdeaModal = $event"
      :viewmode="viewmode"
      :insightDataSelected="insightSelected"
      @insightListSelected-updated="insightSelected = $event"
    />
  </CCardBody>
</template>

<script>
import InsightModal from "@/views/insight/InsightModal";
import IdeaModal from "../idea/IdeaModal";
import store from '../../store'
import axios from 'axios'
var URLLogLikeInsert = store.getters.URL + '/api/LogLike/insert';
var URLLogLikeDelete = store.getters.URL + '/api/LogLike/delete';

export default {
  name: "InsightTable",
  components: { InsightModal, IdeaModal },
  props: {
    items: Array,
    fields: {
      type: Array,
      default() {
        return ["CreatorName"];
      }
    },
    hover: Boolean,
    striped: Boolean,
    bordered: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    header: Boolean,
    loading:Boolean
  },
  data() {
    return {
      viewmode: "create",
      InsightModal: false,
      InsightObj: null,
      IdeaModal: false,
      dataTables:[],
      insightSelected: []
    };
  },
  methods: {
    onClickDetail(action,data) {

      //console.log("data",data);

      switch (action) {
        case "Insight":
          this.InsightModal = true;
          this.InsightObj = null;
          setTimeout(function(){ this.InsightObj = data; }.bind(this), 100);
          break;

        case "Idea":
          let model = {
            InsightNo: data.InsightNo,
            InsightDetail: data.Description
          };

          this.insightSelected = [];
          this.insightSelected.push(model);

          this.IdeaModal = true;
          break;
      }

    },
    onClickLike(item) {

      let logLikeModel = {
        "DocumentNo": item.InsightNo,
        "LikeUser": item.UserLike,
        "DocumentType": 'Insight'
      };

      if(item.ILike) {
        axios.post(URLLogLikeDelete, logLikeModel)
           .then(response => {
             item.TotalLike--;
             item.ILike = !item.ILike;
             this.$_toast_Noti('success','success', response.data);
           })
           .catch(error => {
             this.$_toast_Noti('error','error', error);
           });
      } 
      else {
        axios.put(URLLogLikeInsert, logLikeModel)
           .then(response => {
             item.TotalLike++;
             item.ILike = !item.ILike;
             this.$_toast_Noti('success','success', response.data);
           })
           .catch(error => {
             this.$_toast_Noti('error','error', error);
           });
      }

    }
  },
  computed: {
    insightTable: {
      get() {
        return this.items;
      },
      set(v) {
        this.$emit("insighttable-updated", v);
      }
    }
  },
  watch: { 
    items: function(newVal, oldVal) { // watch it
      //console.log('items changed: ', newVal, ' | was: ', oldVal)
      this.dataTables = newVal;
      //console.log('dataTables = ', this.dataTables)
    }
  }

};
</script>
