<template>
  <div class="container">
    <CRow>
      <CCol class="col pr-2">
        <CLink
          class="font-weight-bold font-sm btn-block text-muted"
          @click="InsightModal = !InsightModal"
        >
          <CWidgetIcon
            text="+ Add Insight"
            color="customtheme1"
            :icon-padding="false"
          >
            <CIcon name="cil-lightbulb" size="xl" class="mx-2" width="20"
          /></CWidgetIcon>
        </CLink>
      </CCol>

      <CCol class="col pl-2 pr-2">
        <CLink
          class="font-weight-bold font-sm btn-block text-muted"
          @click="IdeaModal = !IdeaModal"
        >
          <CWidgetIcon
            text="+ Add Idea"
            color="customtheme2"
            :icon-padding="false"
          >
            <CIcon name="cil-sun" size="xl" class="mx-2" width="20" />
          </CWidgetIcon>
        </CLink>
      </CCol>

      <CCol class="col pl-2 pr-2">
        <CLink
          class="font-weight-bold font-sm btn-block text-muted"
          @click="seletConceptType('Idea')"
        >
          <CWidgetIcon
            text="+ Add Concept"
            color="customtheme3"
            :icon-padding="false"
          >
            <CIcon name="cil-smile-plus" size="xl" class="mx-2" width="20" />
          </CWidgetIcon>
        </CLink>
      </CCol>

      <CCol class="col pl-2 pr-2">
        <CLink
          class="font-weight-bold font-sm btn-block text-muted"
          :to="{ name: 'My Task' }"
        >
          <CWidgetIcon
            text="Show My Tasks"
            color="customtheme4"
            :icon-padding="false"
          >
            <CIcon name="cil-library" size="xl" class="mx-2" width="20" />
          </CWidgetIcon>
        </CLink>
      </CCol>

      <CCol class="col pl-2 pr-2">
        <CLink
          class="font-weight-bold font-sm btn-block text-muted"
          :to="{ name: 'History' }"
        >
          <CWidgetIcon
            text="See History"
            color="customtheme5"
            :icon-padding="false"
          >
            <CIcon name="cil-inbox" size="xl" class="mx-2" width="20" />
          </CWidgetIcon>
        </CLink>
      </CCol>
    </CRow>

    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol>
                <h4 class="text-top-dashboard">
                  <CIcon name="cil-grid" />
                  Top Insight
                </h4>
              </CCol>

              <div class="action-right">
                <CButtonGroup>
                  <CButton
                    id="btnRecent"
                    style="background-color:#34b8c3; color:#ffffff;"
                    v-on:click="onTopInsightClick(true)"
                    >Recent</CButton
                  >
                  <CButton
                    id="btnPopular"
                    style="background-color:#608783; color:#ffffff"
                    v-on:click="onTopInsightClick(false)"
                    >Popular</CButton
                  >
                </CButtonGroup>
              </div>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <InsightTable
              :items.sync="InsightDatatable"
              @insighttable-updated="InsightDatatable = $event"
              :header="false"
              :loading="IsLoading"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- <CRow>
      <CCol md="12">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="12" lg="12">
                <CRow>
                  <CCol sm="6">
                    <CCallout color="info">
                      <small class="text-muted">INSIGHTS IN TANK</small><br />
                      <strong class="h4">{{sumInsightTank}}</strong>
                    </CCallout>
                  </CCol>
                  <CCol sm="6">
                    <CCallout color="danger">
                      <small class="text-muted">CONCEPTS IN TANK</small><br />
                      <strong class="h4">{{sumConceptTank}}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="6">
        <CCard>
          <CCardHeader>Insight Pie Chart By Lens</CCardHeader>
          <CCardBody><CChartPieInsight
          :datasets="InsightDataByLens"
          :labels="lensLabel" /></CCardBody>
        </CCard>
      </CCol>
      <CCol col="6">
        <CCard>
          <CCardHeader>Insight Bar Chart By Industry</CCardHeader>
          <CCardBody
            ><CChartBarConcept
              :datasets="InsightDataByIndustry"
              :labels="labelsIndustry"
          /></CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="6">
        <CCard>
          <CCardHeader>Concept Bar Chart By Segment</CCardHeader>
          <CCardBody>
            <CChartBarConcept
              :datasets="ConceptDataBySegment"
              :labels="labelsSegment"
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol col="6">
        <CCard>
          <CCardHeader>Concept Bar Chart By Industry</CCardHeader>
          <CCardBody
            ><CChartBarConcept
              :datasets="ConceptDataByIndustry"
              :labels="labelsConceptIndustry"
          /></CCardBody>
        </CCard>
      </CCol>
    </CRow> -->

    <InsightModal
      :showModal.sync="InsightModal"
      @InsightModal-updated="InsightModal = $event"
      :item.sync="InsightObj"
      @InsightObject-updated="InsightObj = $event"
      :viewmode="viewmode"
    />

    <IdeaModal
      :showModal.sync="IdeaModal"
      @IdeaModal-updated="IdeaModal = $event"
      :viewmode="viewmode"
      :insightDataSelected="InsightSelected"
      @insightListSelected-updated="InsightSelected = $event"
    />

    <ConceptModal
      :showModal.sync="ConceptModal"
      @ConceptModal-updated="ConceptModal = $event"
      :TypeConcept.sync="TypeConcept"
      :ideaDataSelected="IdeaList"
      @ideaListSelected-updated="IdeaList = $event"
      :viewmode="viewmode"
    />
  </div>
</template>

<script>
import Vue from "vue";

import InsightTable from "../insight/InsightTable";
import IdeaModal from "../idea/IdeaModal";
import ConceptModal from "../concept/ConceptModal";
import InsightModal from "../insight/InsightModal";
import axios from "axios";
import store from "../../store";

import * as Charts from "../charts/indext";
import TheSidebarVue from "../../containers/TheSidebar.vue";

// let userProfile = JSON.parse(localStorage.getItem("userprofile"));
let userProfile = JSON.parse(localStorage.getItem("userprofile"));

export default Vue.extend({
  data() {
    return {
      page: 1,
      IdeaModal: false,
      ConceptModal: false,
      TypeConcept: null,
      InsightModal: false,
      InsightObj: null,
      viewmode: "create",
      InsightList: [],
      IdeaList: [],
      InsightSelected: [],
      userProfile,

      labelsIndustry: [],
      labelsSegment: [],
      InsightDatatable: [],
      username: userProfile.Username,
      displayName: userProfile.DisplayName,
      IsLoading: true,
      sumInsightTank:"",
      sumConceptTank:"",
      isRecent: true,
      sumInsight:[],
      conceptSegment:[],
      labelsConceptIndustry:[],
      conceptIndustry:[],
      insightByLens:[],
      lensLabel:[]
    };
  },
  name: "Dashboard",
  components: {
    InsightTable,
    ...Charts,
    IdeaModal,
    ConceptModal,
    InsightModal,
  },
  mounted() {
    // this.GetInsightData();

    let btn = document.getElementById("btnRecent");
    btn.click();
    // this.GetSumInsightTank()
    // this.GetSumConceptTank()
    // this.GetInsightByIndustry()
    // this.GetConceptBySegment()
    // this.GetConceptByIndustry()
    // this.GetInsightByLens()
  },
  methods: {
    seletConceptType(type) {
      this.TypeConcept = type;

      this.ConceptModal = true;
    },
    onTopInsightClick(value) {
      this.isRecent = value;
      this.GetInsightByClick();
    },
    GetInsightByClick() {
      if (this.isRecent === true) {
        this.sortInsightByRecent();
      } else {
        this.sortInsightByTopLike();
      }
    },
    sortInsightByRecent() {
      axios
        .get(
          store.getters.URL + "/api/IdeationInsight/getdashboardinsightdata",
          {
            params: {
              Username: this.username
            }
          }
        )
        .then(res => {
          //let Obj = JSON.parse(res.data.Value);
          if(res.data.StatusCode === 200 && res.data.Message === 'Success'){
            this.InsightDatatable = res.data.Data
          }         
          this.IsLoading = false;
        }).catch(err => {
          console.error(err)
          this.IsLoading = false;
        }).finally(() =>{
          this.GetSumInsightTank()
        })
    },
    sortInsightByTopLike() {
      axios
        .get(
          store.getters.URL +
            "/api/IdeationInsight/getdashboardinsighbytoplike",
          {
            params: {
              Username: this.username
            }
          }
        )
        .then(res => {
          if(res.data.StatusCode === 200 && res.data.Message === 'Success'){
            this.InsightDatatable = res.data.Data
          }
          this.IsLoading = false;
        }).catch(err => {
          console.error(err)
          this.IsLoading = false;
        })
    },
    GetSumInsightTank() {
      axios
        .get(store.getters.URL + "/api/IdeationInsight/getsuminsighttank")
        .then(res => {
          //console.log("Data",res.data);
          if(res.data.StatusCode === 200){
              this.sumInsightTank = res.data.sumInsight
          }
          else{
            this.sumInsightTank = 0
          }
        })
        .catch(err => {
          console.error(err.Message)
        }).finally(() => {
          this.GetSumConceptTank()
        })
    },
    GetSumConceptTank(){
      axios.get(store.getters.URL + '/api/IdeationConcept/getsumconcepttank').then(res => {
          if(res.data.StatusCode === 200){
              this.sumConceptTank = res.data.sumConcept
          }
          else{
            this.sumConceptTank = 0
          }
      }).catch(err => {
        console.error(err.Message)
      }).finally(() =>{
        this.GetInsightByLens()
      })
    },
    GetInsightByIndustry(){
      axios.get(store.getters.URL + "/api/IdeationInsight/getinsightbyindustry").then(res => {
        if(res.data.StatusCode === 200){
          let labelIndustry = []
          let getSum = []
          res.data.Data.forEach(function(data) {
            labelIndustry.push(data.IndustryDesc)
            getSum.push(data.SumIndustry)
          });
          this.labelsIndustry = labelIndustry
          this.sumInsight = getSum
        }
        //console.log(res.data.Message)
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.GetConceptBySegment()
      })
    },
    GetConceptBySegment(){
      axios.get(store.getters.URL + "/api/IdeationConcept/getconceptbysegment").then(res => {
        if(res.data.StatusCode === 200){
          let labelSegment = []
          let getSum = []
          res.data.Data.forEach(function(data) {
            labelSegment.push(data.SegmentDesc)
            getSum.push(data.SumSegment)
          });
          this.labelsSegment = labelSegment
          this.conceptSegment = getSum
        }
        //console.log(res.data.Message)
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.GetConceptByIndustry()
      })
    },
    GetConceptByIndustry(){
       axios.get(store.getters.URL + "/api/IdeationConcept/getconceptbyindustry").then(res => {
        if(res.data.StatusCode === 200){
          let labelIndustry = []
          let getSum = []
          res.data.Data.forEach(function(data) {
            labelIndustry.push(data.IndustryDesc)
            getSum.push(data.SumIndustry)
          });
          this.labelsConceptIndustry = labelIndustry
          this.conceptIndustry = getSum
        }
        //console.log(res.data.Message)
      }).catch(err => {
        console.error(err)
      })
    },
    GetInsightByLens(){
      axios.get(store.getters.URL + "/api/IdeationInsight/getinsightbylens").then(res => {
        if(res.data.StatusCode === 200){
          let lensDes = []
          let getSum = []
          res.data.Data.forEach(function(data) {
            lensDes.push(
              data.LensDesc
            )
            getSum.push(
              data.SumLens
            )
          });
          this.lensLabel = lensDes
          this.insightByLens = getSum
          //console.log("Desc",this.lensLabel)
        }
        //console.log(res.data.Message)
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.GetInsightByIndustry()
      })
    }
  },
  computed: {
    InsightDataByIndustry() {
      return [
        {
          label: "Industry",
          backgroundColor: "#5f6b6d",
          data: this.sumInsight
        }
      ];
    },
    ConceptDataBySegment() {
      return [
        {
          label: "Segment",
          backgroundColor: "#fd625e",
          data: this.conceptSegment
        }
      ];
    },
    ConceptDataByIndustry() {
      return [
        {
          label: "Industry",
          backgroundColor: "#01b8aa",
          data: this.conceptIndustry
        }
      ];
    },
    InsightDataByLens(){
      return[
        {
          label: "Lens",
          backgroundColor: [
              '#01b8aa',
              '#fd625e',
              '#f2c80f',
              '#374649'
            ],
          data: this.insightByLens
        }
      ]
    }
  },
  watch: {
    InsightObj: function(newVal, oldVal) {
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal);
      if (newVal !== null) {
        this.GetInsightByClick();
        //console.log("InsightDatatable",this.InsightDatatable);
      }
    }
  }
});
</script>
