<template>
  <CChartBar
  :datasets="datasets"
  :labels="labels"/>
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'

export default {
  name: 'CChartBarConcept',
  components: { CChartBar },
  props:{
      labels:Array,
      datasets:Array
  }
}
</script>
