<template>
  <CChartPie
    :datasets="datasets"
    :labels="labels"
  />
</template>

<script>
import { CChartPie } from '@coreui/vue-chartjs'


export default {
  name: 'CChartPieInsight',
  components: { CChartPie },
  props:{
    labels:Array,
    datasets:Array
  }
}
</script>
